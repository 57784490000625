<template>
  <div style="min-width: 650px;">
    <div class="viewleft">
      <div style="padding-top: 20px;">
        <div style="height: 120px;border-bottom: 1px solid #adabab33;">
          <div style="margin: 0 auto;    width: 100px;    height: 50px;">
            <img src="../assets/img.png" style="width: 110px;height: 110px;"/>

          </div>
          <div style="margin-top: 80px; margin-left: 20px">
            <ul>
              <li>
                <span @click="showHtml(0)">Lua语言-1</span>
              </li>
              <li>
                <span @click="showHtml(1)">Lua语言-2</span>
              </li>
            </ul>
          </div>
          <div
              style="position: absolute;    bottom: 0;    line-height: 50px;    width: 201px;    background: #fff;font-size: 12px;text-align: center;">
            <p style="line-height: 20px;">小抱笔记 zdzaq.cn&nbsp;版权所有 </p>
            <p style="line-height: 0px;">
              <a href="https://beian.miit.gov.cn/" target="_blank"
                 style="background: transparent;    text-decoration: none;    color: #333;    cursor: pointer;">京ICP备2022033978号-1</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="left: 245px;width: 85%; height: 95%" class="viewport">
      <iframe
          width="100%" height="100%"  scrolling="yes" frameborder="no" border="0" marginwidth="0" marginheight="0" allowtransparency="yes"
          :src="iframePath"
      ></iframe>


    </div>

  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "index",
  data() {
    return {
      iframePath: 'https://oss-jiaopei-public.oss-cn-hangzhou.aliyuncs.com/test/lua_1.html'
    }
  },
  props: {
    msg: String
  },
  methods: {
    showHtml(type) {
      if (type == 0) {
        this.iframePath = 'https://oss-jiaopei-public.oss-cn-hangzhou.aliyuncs.com/test/lua_1.html'
      } else {
        this.iframePath = 'https://oss-jiaopei-public.oss-cn-hangzhou.aliyuncs.com/test/lua_2.html'
      }
    }
  }
}
</script>

<style scoped>
.viewleft {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 240px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

.viewport {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer {
  width: 100%;
  height: 230px;
  background: #333333;
}

.footer {
  text-align: center;
  height: 40px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
}
</style>